import React from "react";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import Promo from "../../components/promo/Promo";
import HeroSeven from "./HeroSeven";
import VideoPromo from "../../components/promo/VideoPromo";
import Info from "../../components/about/Info";
import Contact from "../../components/contact/Contact";
import KineticStyleAssessments from "../../pages/KineticStyleAssessments";

export default function HomeSeven() {
  return (
    <Layout>
      <Navbar darkBg />
      <HeroSeven />
      <Info />
      {/* <VideoPromo /> */}
      {/* <Promo /> */}
      {/* <Contact bgColor /> */}
      <KineticStyleAssessments justContent />
      <Footer space />
    </Layout>
  );
}
