import React from "react";
import { createContactQueryDB } from "../../utils";

export default function Contact({
  bgColor,
  id = "contact-us",
  title = "Contact With Us",
}) {
  const submitForm = (e) => {
    e.preventDefault();
    const formEl = document.forms.contactForm;
    const formData = new FormData(formEl);
    const contact = {
      name: formData.get("name"),
      email: formData.get("email"),
      phone: formData.get("phone"),
      message: formData.get("message"),
    };
    if (Object.values(contact).every((val) => val != "")) {
      createContactQueryDB(contact);
      formEl.reset();
      alert("Thanks for your query.");
    } else {
      alert("Please provide all fields to continue.");
    }
  };
  return (
    <>
      <section
        id={id}
        className={`contact-us ptb-100 ${bgColor ? "gray-light-bg" : ""}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 pb-3 message-box d-none">
              <div className="alert alert-danger"></div>
            </div>
            <div className="col-md-5">
              <div className="section-heading">
                <h2>{title}</h2>
                <p>
                  If you would like to get in touch with the Kinetic Thinking
                  team, please send us an email and we will get back to you as
                  quickly as possible.
                </p>
              </div>
            </div>
            <div className="col-md-7">
              <form
                id="contactForm"
                className="contact-us-form"
                onSubmit={submitForm}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        placeholder="Enter name"
                        required="required"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="phone"
                        defaultValue=""
                        className="form-control"
                        id="phone"
                        placeholder="Your Phone"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Enter email"
                        required="required"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        id="message"
                        className="form-control"
                        rows="7"
                        cols="25"
                        placeholder="Message"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 mt-3">
                    <button
                      type="submit"
                      className="btn solid-btn"
                      id="btnContactUs"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
              <p className="form-message"></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
