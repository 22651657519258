import React from "react";
import { teams } from "../../teams";

export default function Teams() {
  return (
    <>
      <section className="team-member-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-4">
                <h2>Our Team of Experts</h2>
              </div>
            </div>
          </div>
          <div className="row" id="experts">
            {teams.map((team, index) => (
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="single-team-member position-relative my-lg-3 my-md-3 my-sm-0">
                  <div className="team-image">
                    <img
                      src={`assets/img/teams/${team.dp}`}
                      alt="Team Members"
                      className="img-fluid rounded"
                    />
                  </div>
                  <div className="team-info text-white rounded d-flex flex-column align-items-center justify-content-center">
                    <h5 className="mb-0">{team.name}</h5>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: team.about,
                      }}
                    />
                    <a
                      href={`team-details/${index}`}
                      target="_blank"
                      className="btn app-store-btn mt-3"
                    >
                      Know More
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
