export const teams = [
  {
    name: "Dimo Dimov, Ph.D",
    about: `<div class="et_pb_text_inner"><p>Dimo Dimov is Professor of Innovation &amp; Entrepreneurship at University of Bath, UK, and Co-founder of Kinetic Thinking. Prior to joining the University of Bath in 2012, he was on the faculty at Newcastle University (UK), University of Connecticut (USA), and IE Business School (Spain). Before entering academia, Dimo was finance director with Marriott International, overseeing two hospitality businesses in Budapest, Hungary.</p>
      <p>Dimo is a world leading scholar of entrepreneurship, founding Editor-in-Chief of Journal of Business Venturing Insights, and recognized recently among the<a href="https://www.hottopics.ht/19329/the-100-top-professors-of-entrepreneurship-worldwide/?utm_source=newsletter%20weekly&amp;utm_campaign=699d16db32-Subject_line_40_character_max9_11_2015&amp;utm_medium=email&amp;utm_term=0_00ec283f4c-699d16db32-22994144" target="_blank" rel="noopener noreferrer"> top 100 professors of entrepreneurship worldwide</a>. He brings eclectic rigor to understanding and managing entrepreneurship as a journey. Dimo’s expertise&nbsp;is in the initiation, acceleration, and funding&nbsp;of entrepreneurial ventures. He has received much academic acclaim for his research, synthesized in a recent book, <i>The Reflective Entrepreneur</i>. Dimo teaches entrepreneurship and entrepreneurial finance across all program levels and works with both start-ups and established companies to facilitate the development of mindsets and structures for innovative performance.</p>
      <p>Dimo holds a PhD in Entrepreneurship from London Business School, an MBA from Case Western Reserve University, USA and the CEU Graduate Business School in Budapest, Hungary, and a BSc in Business Administration from Oklahoma City University, USA. He was born in Bulgaria, has lived, studied and worked across a number of countries, and speaks six languages. He currently lives in Bath, UK.</p></div>`,
    dp: "Dimov-200x200.png",
  },
  {
    name: "Joseph Pistrui, Ph.D",
    dp: "Pistrui-200x200.png",
    about: `<div class="et_pb_text_inner"><p>Joseph Pistrui boasts a long-standing international academic career in the fields of entrepreneurship, strategy, innovation, and family business having worked for academic institutions such as IE Business School and IE University, Duke Corporate Education, London School of Economics and Political Science, Bocconi University, IESE Business School and Corvinus University of Budapest. Joseph Pistrui has also served as a member of the Leeds University Business School International Advisory Board since 2014.</p>
      <p>Before co-founding Kinetic Thinking, Joseph Pistrui was a Professor of Entrepreneurship &amp; Innovation at IE Business School. His tenure at IE Business School spans 19 years, during which time he has been a fulltime faculty member of the IE Business School and the IE University and held the positions of Dean for Executive Education, Director of the Entrepreneurial Management Department, Co-Academic Director of the IE-Brown University joint EMBA, and founding Academic Director of the Senior Fellows Program. While at IE Business School Joseph founded the IE Venture Lab accelerator and co-designed the inaugural IE Brown University joint EMBA and served as a founding faculty member of the Global Senior Management Program developed and delivered jointly by IE Business School and University of Chicago-Booth School of Business. Joseph continues to serve as an adjunct professor of entrepreneurship at IE University.</p>
      <p>Joseph earned a Doctor of Philosophy in Strategic Management and Entrepreneurship from Universidad Autonoma de Barcelona, a Master of Arts in Liberal Studies from DePaul University, and a Bachelor of Science in Business Administration from Ferris State University.&nbsp;He was born in Detroit, Michigan USA and has lived and worked in Europe since 1993 and currently resides in Madrid, Spain.</p></div>`,
  },
  {
    name: "Lucía Crespo",
    dp: "Crespo-200x200-3.png",
    about: `<div class="et_pb_text_inner"><p>Lucía Crespo is a seasoned professional of the Executive Education space, with extensive experience creating and delivering high impact leadership programs, both for Telefónica executives and external companies.</p>
      <p>Lucía is Program Director &amp; Faculty at Universitas Telefónica and Career Advisor for the Master in Digital Business &amp; Innovation, at IE School of Human Sciences and Technology.</p>
      <p>Self-leadership, purpose driven leadership, high performing teams, women leadership, agile and new ways of working, creative thinking, design thinking, career planning and much more of the like conform her portfolio, delivered both for small groups and big audiences.</p>
      <p>Lucía combines her teaching activity with career coaching for the students of the Master in Digital Business &amp; Innovation of IE School for Human Sciences and Technology, helping them to devise their strategy for better positioning themselves in the job market (SWOT analysis, search process, LinkedIn, CV and cover letter writing, etc.).</p>
      <p>Lucía joined Telefónica coming from IE Business School, where for several years she acted as career and employability advisor for MBA students and Program Director for ExMBA. She has also worked at Vodafone Spain, delivering added value services for the mobile network, at PwC, as management consultant and at Ono, within the Strategy Financial Planning area. She holds an MD in Electronic Physics, an MBA and a postgrad in Strategic Human Resources Management.</p>
      <p>Lucía is also a very busy mother of three, who enjoys reading, cooking, running and above all, spending quality time with her husband, friends and family.</p></div>`,
  },
  {
    name: "Daniel Soriano",
    dp: "Soriano-200x200-1.png",
    about: `<div class="et_pb_text_inner"><p>Daniel Soriano is Director of the Entrepreneurship &amp; Innovation Center and Adjunct Professor of Entrepreneurship, at IE Business School. He is also Trainer and Coach of Corporate Innovation Projects in Corporations at Headspring Executive Development (Joint Venture of IE and Financial Times).</p>
      <p>Daniel is member of a Tech and Digital investment committee for 3 different venture capital instruments at Caixa Capital Risc. The venture capital instruments invest in startups in different stages, from early stage to Series A (50.000€ – 3.000.000€). Additionally, Daniel is member of the Academic Council at MOTI (Madrid Internet of Things Institute), he is trainer in Design Thinking and Lean Startup methodologies as well as Pitch Coach for start-ups for international investor forums. Daniel has been responsible of launching innovative projects competitions, advisory services, training programs, incubation and acceleration services and special projects connecting the entrepreneurial ecosystem with other stakeholders as corporations, institutions and governments. He has led incubation and acceleration programs to develop intrapreneurial innovation projects in large firms from different countries and sectors, such as banking, energy, telco, media, e-commerce, insurance, distribution, construction, infraestructures, services, IT, pharma and retail.</p>
      <p>On the academic level, he is Adjunct Professor of Entrepreneurship at IE Business School in most MBA Programs. He is the coordinator of Adjunct Faculty at the Entrepreneurship Academic Area and he is the Academic Director of the Business Innovation Module at the Global Executive MBA.</p>
      <p>Professor Soriano obtained the IE Business School MBA degree, he studied Economics and Innovation Management at UPM, UCM y UAM and he is a Computer Science engineer at UPM. He participated in the European Entrepreneurship Colloquium organized by European Forum for Entrepreneurship Research (EFER).</p>
      <p>His interests are focused on start-ups, corporate innovation, venture capital and design thinking.</p></div>`,
  },
  {
    name: "Andrew McCarthy",
    dp: "McCarthy-200x200-1.png",
    about: `<div class="et_pb_text_inner"><p>Andrew Peter Wallace McCarthy is a designer, consultant, and educator. Andrew is based in Madrid and has worked in New York and internationally as a designer, art and creative director, and consultant to multinationals and a great many startups, advising and leading organizations to deliver innovative and sustainable impact via creativity, innovation processes, strategy, customer and user experience, and design.</p>
      <p>Andrew studied philosophy and the history of math and sciences, then design, and now teaches human centered design, visual communications, creativity, innovation, and entrepreneurship as an Associate Professor at Madrid’s IE University and other schools. More recently, Andrew has taken on the role of designer and founding Academic Director of the Master in Customer Experience and Innovation at the IE School of Human Science and Technology. In addition to teaching and project work Andrew speaks and moderates at events and conferences, draws and etches, practices improv and plays music, and his voice is used in ads and audiobooks, at his daughters' bedtimes, and to the startlement of animals great and small.</p></div>`,
  },
  {
    name: "Nora Anderson",
    dp: "Anderson-200x200-1.png",
    about: `<div class="et_pb_text_inner"><p>Nora Anderson is passionate about creating a positive impact for organizations and individuals through transformational learning experiences. Her professional experience spans across three continents and for over 15 years she has partnered with global clients to align business strategies with leadership and talent development solutions. Nora has a proven track record of working closely with C-Suite and senior executives to address pressing business challenges and design scalable solutions to develop future-ready leaders capable of moving organizations forward. Her expertise includes business development, needs assessment, program design, client relationship management, and impact assessment.</p>
      <p>Nora is currently Executive Director of Executive Education at the Carlson School of Management (University of Minnesota) where she provides strategic direction and leadership of custom programs designed and delivered for corporate clients as well as a portfolio of over 20 executive courses focused on business and leadership. She speaks regularly at business events on the topics of leadership and trends in learning and development and she is a member of the Board of Directors of UNICON, the global consortium of university based Executive Education. Nora has lived and worked in Argentina, Spain, Mexico, Chile and Brazil and traveled to over 40 countries. She holds an MBA degree with honors from IE Business School, a B.A. degree from Macalester College, and is fluent in Spanish and Portuguese.</p></div>`,
  },
  {
    name: "Natalie Beinisch, Ph.D",
    dp: "Beinisch-200x200-1.png",
    about: `<div class="et_pb_text_inner">Natalie Beinisch is Senior Researcher and Consultant at NMB Responsible Investment and Governance Consulting in Lagos. Before moving to Lagos, she ran the Engagement Programme for Aegon Asset Management’s Responsible Investment team in the Netherlands and headed the Academic Network at the United Nations Principles for Responsible Investment in London.<p></p>
      <p>Before beginning her work in sustainable finance, Natalie cut her teeth in leadership development, having begun her career at Duke Corporate Education, the world’s leading bespoke provider of executive education. She holds a PhD and an MSc from the London School of Economics and a BA from McGill University in Canada.</p></div>`,
  },
];
