import React from "react";

export default function Footer({ space, prePath = "" }) {
  return (
    <>
      <footer className="footer-section">
        <div
          className={`footer-top background-img-2 ${
            space ? "pt-100" : "pt-60"
          }`}
          style={{
            backgroundImage:
              "linear-gradient(to right, rgba(0, 44, 95, 1), rgba(0, 157, 219, 1))",
          }}
        >
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-12 col-lg-6 mb-6 mb-md-6 mb-sm-6 mb-lg-0">
                <div className="footer-nav-wrap text-white">
                  <img
                    src={`${prePath}assets/img/logo-white-1x.png`}
                    alt="footer logo"
                    width="120"
                    className="img-fluid mb-3"
                  />
                  {/* <p>Educational Services Kinetic Style Assessments</p> */}
                  {/* <div className="social-list-wrap">
                    <ul className="social-list list-inline list-unstyled">
                      <li className="list-inline-item">
                        <a href="#/" target="_blank" title="Facebook">
                          <span className="ti-facebook"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#/" target="_blank" title="Twitter">
                          <span className="ti-twitter"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#/" target="_blank" title="Instagram">
                          <span className="ti-instagram"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#/" target="_blank" title="printerst">
                          <span className="ti-pinterest"></span>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
              {/* <div className="col-md-12 col-lg-6">
                <div className="row">
                  <div className="col-12 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Company</h5>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2">
                          <a href="#contact-us">Contact Us</a>
                        </li>
                        <li className="mb-2">
                          <a href="/privacy-policy">Legal Text</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <div className="footer-bottom border-gray-light mt-5 py-3">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-7">
                  <div className="copyright-wrap small-text">
                    <p className="mb-0 text-white">
                      © Kinetic Thinking, All rights reserved
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
