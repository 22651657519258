import React from "react";
import Contact from "../components/contact/Contact";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/team/PageHeader";
import Teams from "../components/team/Teams";

export default function AboutUs() {
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader HeaderTitle="About Us" PageTitle="About Us" />
      <Teams />
      <Footer space />
    </Layout>
  );
}
