import React from "react";
import Contact from "../components/contact/Contact";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/team/PageHeader";
import { Link } from "react-router-dom";

export default function EducationalServices() {
  const bullet = {
    background: "none",
    padding: 0,
    fontSize: 25,
  };
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader
        HeaderTitle="Educational Services"
        PageTitle="Educational Services"
      />
      <div className="container" id="core-services">
        <div className="section-heading py-5">
          <h2>Core Services</h2>
          <p>
            We offer a menu of educational services that make the development of
            <strong> Kinetic Ways of Working© </strong> accessible to anyone. At
            the heart of the Kinetic Styles framework is the desire to build a
            range of practical and intuitive thinking, managing and leading
            styles for managers and students of all walks of life. With
            education and personal development underpinning everything we do;{" "}
            <span style={{ fontStyle: "italic" }}>
              we believe that anyone can be more entrepreneurial and everyone
              should try.
            </span>
          </p>
        </div>
      </div>
      <div className="container" id="engagement-models">
        <div className="section-heading py-5">
          <h2>Engagement Models</h2>
          <p>
            The Kinetic Thinking team of subject-matter{" "}
            <Link to={"/about-us#experts"}>experts</Link>, experienced
            educational designers and skilled learning facilitators, are
            available to work with clients to help discover how the{" "}
            <strong> Kinetic Ways of Working©</strong> framework can be used to
            build entrepreneurial capabilities in leaders and managers within
            teams, business units, or across an entire organization.
          </p>
        </div>
        <div className="row mb-5">
          <div className="col-md-6 col-lg-6">
            <div
              className="single-promo single-promo-hover single-promo-1 rounded white-bg p-5 h-100"
              style={{
                backgroundColor: "#9f60b5",
              }}
            >
              <div className="color-white">
                <h3 className="color-white">Virtual Engagement Model</h3>
                <p>
                  This model combines the deployment of the{" "}
                  <strong>Kinetic Style Assessments©</strong> framework with
                  automated personal reports and a Self-Directed Debriefing
                  (SDD) virtual course.
                </p>
                <p>
                  <span style={bullet}>●</span> With a fully virtual delivery,
                  this model scales easily across geographies, languages and
                  time zones.
                </p>
                <p>
                  <span style={bullet}>●</span> It can be deployed using a
                  central platform and completed asynchronously by a large and
                  widely distributed population of managers.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6">
            <div
              className="single-promo single-promo-hover single-promo-1 rounded white-bg p-5 h-100"
              style={{
                backgroundColor: "#009ddb",
              }}
            >
              <div className="color-white">
                <h2 className="color-white">Certified Facilitator Model</h2>
                <p>
                  This model combines the deployment of the{" "}
                  <strong>Kinetic Style Assessments©</strong> framework with a
                  certified facilitator who leads a debriefing workshop.
                </p>
                <p>
                  <span style={bullet}>●</span> This model targets executives
                  and general managers.
                </p>
                <p>
                  <span style={bullet}>●</span> It can be deployed as a stand
                  along learning model or integrated into a wider learning
                  design for a more holistic experience.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 mt-4">
            <div
              className="single-promo single-promo-hover single-promo-1 rounded white-bg p-5 h-100"
              style={{
                backgroundColor: "#ff6f20",
              }}
            >
              <div className="color-white">
                <h2 className="color-white">Expert Model</h2>
                <p>
                  This model combines the deployment of the{" "}
                  <strong>Kinetic Style Assessments©</strong> framework with
                  Kinetic Thinking expert facilitation and executive development
                  workshop.
                </p>
                <p>
                  <span style={bullet}>●</span> This model targets senior
                  executives and general managers.
                </p>
                <p>
                  <span style={bullet}>●</span> It can be deployed as a stand
                  along learning model or integrated into a wider learning
                  design for a more holistic experience.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 mt-4">
            <div
              className="single-promo single-promo-hover single-promo-1 rounded white-bg p-5 h-100"
              style={{
                backgroundColor: "#a9ac16",
              }}
            >
              <div className="color-white">
                <h2 className="color-white">Business Model</h2>
                <p>
                  There are three dimensions to the{" "}
                  <strong> Kinetic Ways of Working©</strong> business model
                  designed to serve a range of client needs and to align with
                  the engagement model of choice for specific client needs.
                </p>
                <p>
                  <span style={bullet}>●</span> A licensing use fee for the
                  deployment of the bespoke Kinetic Style Assessments©
                  framework, the automated reporting platform, and the
                  Self-Directed Debrief modules.
                </p>
                <p>
                  <span style={bullet}>●</span> A facilitator training fee to
                  achieve certification and gain access to the proprietary
                  Kinetic Style instructional tools and supporting materials.
                </p>
                <p>
                  <span style={bullet}>●</span> An educator fee for the direct
                  participation of a member of the Kinetic Style expert team.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact
        bgColor="gray-light-bg"
        id="request-for-proposals"
        title="Request for Proposals"
      />
      <Footer space />
    </Layout>
  );
}
