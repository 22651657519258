import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { app } from "../../../constants";
import { getAdminurl } from "../../../utils";

const Navbar = ({ darkBg, classOption, prePath = "" }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const stickyheader = document.querySelector(".header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <>
      <header className={`header ${classOption}`}>
        <nav
          className={`navbar navbar-expand-lg fixed-top ${
            darkBg ? "bg-transparent" : "custom-nav white-bg"
          } ${scroll > headerTop ? "affix" : ""}`}
        >
          <div className="container">
            <Link to="/" className="navbar-brand">
              {darkBg ? (
                <img
                  src={`${prePath}assets/img/logo-white-1x.png`}
                  width="90"
                  alt="logo"
                  className="img-fluid"
                />
              ) : (
                <img
                  src="assets/img/logo-color-1x.png"
                  width="120"
                  alt="logo"
                  className="img-fluid"
                />
              )}
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="ti-menu"></span>
            </button>
            <div
              className="collapse navbar-collapse main-menu"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to="/">
                    Home
                  </HashLink>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/"
                    className="nav-link  dropdown-toggle"
                    href="#"
                    id="navbarDropdownAbout"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    About Us
                  </Link>
                  <div
                    className="dropdown-menu submenu"
                    aria-labelledby="navbarDropdownAbout"
                  >
                    <HashLink
                      to="/about-us#experts"
                      className="dropdown-item"
                      elementId="experts"
                      smooth
                    >
                      Team of Experts
                    </HashLink>
                    {/* <HashLink
                      to="/about-us#research-and-publishing"
                      className="dropdown-item"
                      elementId="esearch-and-publishing"
                      smooth
                    >
                      Research & Publishing
                    </HashLink> */}
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/"
                    className="nav-link  dropdown-toggle"
                    href="#"
                    id="navbarDropdownEducation"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Educational Services
                  </Link>
                  <div
                    className="dropdown-menu submenu"
                    aria-labelledby="navbarDropdownEducation"
                  >
                    <HashLink
                      to="/ed-services#core-services"
                      className="dropdown-item"
                      elementId="core-services"
                      smooth
                    >
                      Core Services
                    </HashLink>
                    <HashLink
                      to="/ed-services#engagement-models"
                      className="dropdown-item"
                      elementId="engagement-models"
                      smooth
                    >
                      Engagement Models
                    </HashLink>
                    <HashLink
                      to="/ed-services#request-for-proposals"
                      className="dropdown-item"
                      smooth
                      elementId="request-for-proposals"
                    >
                      Request for Proposals
                    </HashLink>
                  </div>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    smooth
                    to={getAdminurl() + "login"}
                  >
                    Dashboard
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
