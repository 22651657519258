import React from "react";

const Info = () => {
  return (
    <>
      <section
        className="promo-section"
        style={{
          padding: "50px 0px",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-heading text-center">
                <h4 style={{ fontWeight: 600 }}>
                  Staying ahead in the age of AI will require new ways of
                  thinking, managing and leading to navigate the entrepreneurial
                  journey toward innovation
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Info;
