import React from "react";
import { teams } from "../../teams";

export default function TeamMember({ team }) {
  return (
    <>
      <section className="team-single-section ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-sm-12 col-lg-5">
              <div className="team-single-img">
                <img
                  src={`../assets/img/teams/${team.dp}`}
                  alt="member"
                  className="img-fluid w-100"
                />
              </div>
            </div>
            <div className="col-md-12 col-sm-12 col-lg-6">
              <div className="team-single-text">
                <div className="team-name mb-4">
                  <h4 className="mb-1">{team.name}</h4>
                </div>
                <div
                  className="mt-20"
                  dangerouslySetInnerHTML={{
                    __html: team.about,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
