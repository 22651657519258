import React from "react";
import { Routes, Route } from "react-router-dom";
import ComingSoon from "../components/others/ComingSoon";
import NotFound from "../components/others/NotFound";
import ThankYou from "../components/others/ThankYou";
import HomeSeven from "../themes/index/HomeSeven";
import AboutUs from "../pages/AboutUs";
import TeamDetails from "../pages/TeamDetails";
import ScrollToTop from "./ScrollToTop";
import EducationalServices from "../pages/EducationalServices";
import KineticStyleAssessments from "../pages/KineticStyleAssessments";
import PrivacyPolicy from "../pages/PrivacyPolicy";

const AppRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<HomeSeven />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/team-details/:teamId" element={<TeamDetails />} />
        <Route exact path="/ed-services" element={<EducationalServices />} />
        <Route exact path="/ep-style" element={<KineticStyleAssessments />} />
        <Route exact path="/thank-you" element={<ThankYou />} />
        <Route exact path="/coming-soon" element={<ComingSoon />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
