const __DEV__ = process.env.NODE_ENV == "development";
export const app = {
  colorOne: "#F49611",
  colorTwo: "#2EA3F2",
  lightColor: "rgb(244, 150, 17,0.1)",
  __DEV__,
  admin_url: "https://dashboard.mykineticstyle.com",
  local_admin_url: "http://localhost:3001",
  api: __DEV__
    ? "http://127.0.0.1:8000/api/v1/"
    : "https://kineticthinking-server-ec9576c00f06.herokuapp.com/api/v1/",
};
