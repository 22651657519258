import React from "react";
import { Link } from "react-router-dom";
export default function HeroSeven() {
  return (
    <>
      <section
        className="hero-section background-img ptb-100"
        style={{
          background:
            "url('assets/img/hero-img.jpeg')no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12">
              <div className="position-relative mt-lg-0 mt-md-0 mt-5 text-white">
                <h1 className="text-white text-center py-5 my-5">
                  Kinetic Styles for New Ways of Working
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
