import React from "react";
import Contact from "../components/contact/Contact";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/team/PageHeader";
import KineticStyleAssessmentsFooter from "../components/features/KineticStyleAssessmentsFooter";
import { getAdminurl } from "../utils";

function KineticStyleAssessments() {
  return (
    <>
      <div className="container pb-4 mb-4">
        <p>
          The <strong>Kinetic Style Assessments©</strong> framework prepares you
          for when the future taps you on the shoulder – what we call
          “entrepreneurial situations”. The framework can be used with
          individuals, groups, and entire organizations to help build a deeper
          understanding of both existing ways of thinking, managing and leading
          as well as establish developmental pathways to new ways of working.
          Each assessment acts as a “mirror”, revealing current habits of
          thinking, managing, and leading. It comes with a “map” of four
          distinct styles of thinking, managing, and leading. The mirror and map
          help you make sense of where you are and where you can go.
        </p>
      </div>
      <div className="gray-light-bg pb-5 pt-5">
        <div className="container ">
          <div className="row equal">
            <div className="col-12 col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded white-bg p-5 h-100 d-flex flex-column">
                <center>
                  <img src="assets/img/assessments/ktsam.png" width="200" />
                </center>
                <p className="mb-0 text-bold t-color-1">THINKING</p>
                <h5>Kinetic Thinking Style Assessment and Matrix©</h5>
                <p className="flex-1">
                  Entrepreneurial situations call for different ways of
                  thinking. The Kinetic Thinking Style assessment acts as a
                  mirror of your thinking habits or style. The corresponding
                  Kinetic Thinking Styles Matrix provides a map of four distinct
                  thinking styles. Taking the assessment will help you become a
                  more versatile thinker.
                </p>
                <button
                  type="submit"
                  className="btn solid-btn"
                  onClick={() => window.open(getAdminurl() + "ktsa")}
                >
                  Take The Assessment
                </button>
              </div>
            </div>
            <div className="col-12 col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded white-bg p-5 h-100 d-flex flex-column">
                <center>
                  <img src="assets/img/assessments/kmsam.png" width="200" />
                </center>
                <p className="mb-0 text-bold t-color-2">MANAGING</p>
                <h5>Kinetic Managing Style Assessment and Matrix©</h5>
                <p className="flex-1">
                  Entrepreneurial situations call for different ways of
                  managing. The Kinetic Managing Style assessment acts as a
                  mirror of your managing habits or style. The corresponding
                  Kinetic Managing Styles Matrix provides a map of four distinct
                  managing styles. Taking the assessment will help you become a
                  more versatile manager.
                </p>
                <button
                  type="submit"
                  className="btn solid-btn"
                  onClick={() => window.open(getAdminurl() + "kmsa")}
                >
                  Take The Assessment
                </button>
              </div>
            </div>
            <div className="col-12 col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded white-bg p-5 h-100 d-flex flex-column">
                <center>
                  <img src="assets/img/assessments/klsam.png" width="200" />
                </center>
                <p className="mb-0 text-bold t-color-3">LEADING</p>
                <h5>Kinetic Leading Style Assessment and Matrix©</h5>
                <p className="flex-1">
                  Entrepreneurial situations call for different ways of leading.
                  The Kinetic Leading Style assessment acts as a mirror of your
                  leading habits or style. The corresponding Kinetic Leading
                  Styles Matrix provides a map of four distinct leading styles.
                  Taking the assessment will help you become a more versatile
                  leader.
                </p>
                <button
                  type="submit"
                  className="btn solid-btn"
                  onClick={() => window.open(getAdminurl() + "klsa")}
                >
                  Take The Assessment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container py-5" id="take-the-assessments">
        <div className="section-heading">
          <h2>Take the assessments</h2>
          <p>
            To take the assessments and receive personalized Kinetic Thinking,
            Managing and Leading Style © reports, follow the links below with
            these instructions:
          </p>
        </div>
        <p className="text-bold">
          1) Register as a user (only e-mail and username required).
        </p>
        <p className="text-bold">2) Take the assessment (about 5 min).</p>
        <p className="text-bold">
          3) Review your results and download your personal report. The report
          provides you with a benchmark to other executives who have taken the
          assessment.
        </p>
        <div className="row equal mt-5">
          <div className="col-12 col-md-4 col-lg-4">
            <div className="single-promo single-promo-hover single-promo-1 text-center rounded text-center white-bg p-5 h-100 t-bg-1">
              <h5 className="color-white">Kinetic Thinking Style Assessment</h5>
            </div>
          </div>
          <div className="col-12 col-md-4 col-lg-4">
            <div className="single-promo single-promo-hover single-promo-1 text-center rounded text-center white-bg p-5 h-100 t-bg-2">
              <h5 className="color-white">Kinetic Managing Style Assessment</h5>
            </div>
          </div>
          <div className="col-12 col-md-4 col-lg-4">
            <div className="single-promo single-promo-hover single-promo-1 text-center rounded text-center white-bg p-5 h-100 t-bg-3">
              <h5 className="color-white">Kinetic Leading Style Assessment</h5>
            </div>
          </div>
        </div>
        <p className="text-center mt-3">
          Each assessment will take up to 5 minutes to complete. Upon completion
          you will receive a free PDF personal report with your results. This
          report will provide you with insights into your own thinking, managing
          or leading style. The data will be used for research purposes only. Be
          assured that all answers you provide are anonymous and will be kept in
          strict confidence.
        </p>
      </div> */}
      <KineticStyleAssessmentsFooter />
    </>
  );
}

export default ({ justContent }) => {
  if (justContent) return <KineticStyleAssessments />;
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader
        HeaderTitle="Kinetic Style Assessments"
        PageTitle="Kinetic Style Assessments"
      />
      <KineticStyleAssessments />
      <Footer space />
    </Layout>
  );
};
