import React from "react";

export default function KineticStyleAssessmentsFooter() {
  return (
    <>
      <section
        className="about-us ptb-100 gray-light-bg"
        id="assessment-of-teams-and-organizations"
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h2>Assessment of Teams and Organizations</h2>
                <h3>The Challenge</h3>
                <p>
                  Entrepreneurial capabilities are widely recognized as catalyst
                  for innovation and the general advancement of society. Their
                  development calls for new ways of thinking, managing, and
                  leading across the organization. To address this challenge, we
                  need to build awareness of current practices and then
                  co-create a map of the possible developmental trajectories
                  that focus on building new entrepreneurial-minded
                  capabilities. If we think of the new logic of action as a new
                  coat of paint, its biggest enemy is the old layer of paint,
                  i.e. entrenched habits and preconceptions. Unless we scrape
                  off its loose bits by creating awareness and enabling
                  reflection, the new logic will not take hold.
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src="assets/img/composition-team-organizations.png"
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-us ptb-100">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src="assets/img/new-assessment-application-triangle.png"
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h3>Assessment Applications</h3>
                <p>
                  The instruments have been designed to help individuals, teams,
                  units and divisions, or entire organizations assess,
                  understand, and reflect on their preferred styles of thinking,
                  managing, and leading. Using the{" "}
                  <strong>
                    {" "}
                    Kinetic Thinking, Managing and Leading Styles ©
                  </strong>{" "}
                  framework offers substantial opportunities for building
                  entrepreneurial capacity within organizations. Because the
                  instruments captures current approaches to dealing with
                  entrepreneurial situations, they can help construct paths for
                  personal development as well as serve to monitor the evolution
                  of entrepreneurial attitudes over time in both work and
                  educational settings.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="about-us ptb-100 gray-light-bg"
        id="about-the-assessment"
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12">
              <div className="about-content-left section-heading">
                <h2>About the assessment</h2>
                <h3>Conceptual Framework</h3>
                <p>
                  The{" "}
                  <span style={{ fontStyle: "italic" }}> Kinetic Styles ©</span>{" "}
                  framework, models, and assessment tools are based on pairs of
                  attitudinal constructs that determine how individuals engage
                  with entrepreneurial situations. The full academic background
                  is available in this{" "}
                  <a
                    href="https://www.sciencedirect.com/science/article/pii/S2667277423000026"
                    target="_blank"
                  >
                    published paper
                  </a>
                  .
                </p>
                <h3>The Science</h3>
                <p>
                  The model and assessment frameworks blend insights from
                  experiential learning theory and the specific context of
                  entrepreneurial thinking and decision-making. Experiential
                  learning is a core aspect of management learning and is based
                  on the idea that knowledge is created from the transformation
                  of experience. The same dialectic interaction between
                  individual and situation lies at the foundation of the Kinetic
                  Styles models
                </p>
                <p>
                  The instruments have been validated to ensure that each
                  captures meaningful variations among people and is distinct
                  from other personal characteristics. Their theoretically
                  intuitive relationships with related characteristics suggest
                  they are well positioned within the nomological network of
                  thinking, managing and leading characteristics, exhibiting
                  both convergent and divergent validity.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
