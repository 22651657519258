import React from "react";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/team/PageHeader";
import TeamMember from "../components/team/TeamMember";
import { useParams } from "react-router-dom";
import { teams } from "../teams";

export default function TeamDetails({ params }) {
  const { teamId } = useParams();
  const team = teams[teamId > teams.length ? 0 : teamId];
  return (
    <Layout>
      <Navbar darkBg prePath="../" />
      <PageHeader HeaderTitle="Our Team" PageTitle="Our Team" />
      <TeamMember team={team} />
      <Footer space prePath="../" />
    </Layout>
  );
}
