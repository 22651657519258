import React from "react";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/team/PageHeader";

export default function PrivacyPolicy() {
  return (
    <Layout>
      <Navbar darkBg />
      <PageHeader HeaderTitle="Privacy Policy" PageTitle="Privacy Policy" />
      <div class="container my-5">
        <h2>
          <span>Privacy of Information</span>
        </h2>
        <p>
          The information you provide as part of this record is used to allow
          you to access your personal evaluation profile, as well as to offer
          you the opportunity to perform the evaluation under the same profile
          at a later time. Their responses are used in research on thought
          patterns, but all personally identifiable information is removed as
          part of that investigation. The results of the investigation are
          reported anonymously and aggregate.
        </p>
        <p>
          We will not sell or transfer your personally identifiable information.
        </p>
        <p>
          We do not seek to collect personally identifiable information through
          this website.
        </p>
        <p>
          We keep your information no longer than necessary for the purposes for
          which the information is processed.
        </p>
        <p>
          You have the right to know at all times if we have personally
          identifiable information about you and, if so, you have the right to
          request that (1) you be given access to such information or a copy
          thereof; (2) update any personal information that is outdated or
          incorrect; (3) any personally identifiable information we have about
          you is deleted; or (4) limit the manner in which such information is
          treated.
        </p>
        <p>
          We believe that the confidentiality and security of your information
          are of the greatest importance. We take appropriate measures to
          maintain the confidentiality and security of your data.
        </p>
        <p>
          If you have any questions, please contact us at{" "}
          <a href="mailto:info@kineticthinking.com">info@kineticthinking.com</a>
        </p>
      </div>
      <Footer space />
    </Layout>
  );
}
